<template>
	<div class="authPage">
		<div
			class="content-top-bar d-flex justify-content-between align-items-center bg-white"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		>
			<router-link :to="{ name: 'auth.login.email' }" class="text-dark d-flex">
				<span class="material-icons-outlined" aria-label="back">
					arrow_back
				</span>
			</router-link>
		</div>
		<div
			class="mx-auto"
			:class="{
				'w-100': helpers.layout.isMobile(),
				'w-50 pt-40 pb-40': !helpers.layout.isMobile(),
			}"
		>
			<div
				:class="{
					'py-4 px-3': helpers.layout.isMobile(),
				}"
			>
				<h1 class="text-center mb-5rem">How can we help you?</h1>

				<div>
					<router-link
						v-slot="{ navigate }"
						:to="{ name: 'auth.password.email' }"
						custom
					>
						<button
							class="w-100 btn btn-outline-dark rounded-pill mb-3"
							@click="navigate"
						>
							I forgot my password
						</button>
					</router-link>

					<button
						class="w-100 btn btn-outline-dark rounded-pill mb-3"
						onclick=" window.open('https://help.intedashboard.com/i-do-not-know-my-own-learner-account-email-or-password','_blank')"
					>
						I forgot my email
					</button>

					<button
						class="w-100 btn btn-outline-dark rounded-pill"
						onclick=" window.open('https://help.intedashboard.com/i-am-unable-to-login','_blank')"
					>
						I’ve tried everything, still can’t sign in
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { inject } from "vue";
export default {
	setup() {
		const helpers = inject("helpers");

		return {
			helpers,
		};
	},
};
</script>
<style lang="scss" scoped>
.container {
	align-self: center;
	display: flex;
}
</style>
